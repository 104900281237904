import React from "react";
import { IoMdGitBranch } from "react-icons/io";
import { Link } from "react-router-dom";
import bgImage from './image/bg.svg';
import './Academy.css';


//import Image from "next/image";
//import Link from "next/link";

export default function HeroDwt() {
  return (
    <section className="masthead -type-4">
      <div className="container">
        <div className="row y-gap-50 justify-center items-center">
          <div
            className="col-xl-5 col-lg-6"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div className="masthead__content">
              <h1 className="masthead__title">
                Master Tech Skills, Land{" "}
                <span className="text-purple-1 underline">Remote Jobs</span>
              </h1>
              <p className="masthead__text pt-15">
                Learn from World Experts, Build Portfolio with Life Projects.  
                <br className="md:d-none" />
                Join Thousands of Learners Today and Get Certified.
              </p>
              <div className="masthead__button row x-gap-20 y-gap-20 pt-30">
                <div className="col-auto">
                  <a
                    href="https://academy.datawiztech.com/"
                    className="button -md -purple-1 -rounded text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Enroll Now
                  </a>
                </div>
                <div className="col-auto">
                  <a
                    href="https://academy.datawiztech.com/courses/"
                    className="button -md -outline-dark-1 -rounded text-dark-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Find Courses
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-xl-6 col-lg-6"
            data-aos="fade-up"
            data-aos-delay="750"
          >
            <div className="masthead__image">
              <img
                width={700}
                height={410}
                src={bgImage}
                alt="Illustration of technology-related concept"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
