import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './AISolution.css';
import Header from "../Header/Header";


const AISolution = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("ai");
  return (
    <div className="ai-landing-container">
  
      <Header active={active} />
      {/* Hero Section */}
      <section className="ai-hero" id="home">
        <div className="ai-header-content">
          <h1>Experience the Power of AI</h1>
          <p>Transform your projects and business with cutting-edge AI solutions</p>
          <div className="ai-cta-buttons">
            <button 
              className="ai-cta-button"
              onClick={() => window.open('/inquiry', '_blank')}
            >
              Contact Us
            </button>
            <button 
              className="ai-cta-button secondary"
              onClick={() => {
                document.getElementById('products').scrollIntoView({ 
                  behavior: 'smooth',
                  block: 'start'
                });
              }}
            >
              Our Products
            </button>
          </div>
        </div>
        <div className="ai-stats">
          <div className="ai-stat-item">
            <h3>99.9%</h3>
            <p>Accuracy</p>
          </div>
          <div className="ai-stat-item">
            <h3>50M+</h3>
            <p>Predictions</p>
          </div>
          <div className="ai-stat-item">
            <h3>1000+</h3>
            <p>Clients</p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="ai-features">
        <h2>AI Capabilities</h2>
        <div className="ai-features-grid">
          <div className="ai-feature-card">
            <div className="ai-feature-icon">🤖</div>
            <h3>Machine Learning</h3>
            <p>Advanced algorithms that learn and adapt from data</p>
          </div>
          <div className="ai-feature-card">
            <div className="ai-feature-icon">🗣️</div>
            <h3>Natural Language</h3>
            <p>Process and understand human language naturally</p>
          </div>
          <div className="ai-feature-card">
            <div className="ai-feature-icon">👁️</div>
            <h3>Computer Vision</h3>
            <p>Analyze and understand visual information</p>
          </div>
          <div className="ai-feature-card">
            <div className="ai-feature-icon">📊</div>
            <h3>Predictive Analytics</h3>
            <p>Make data-driven predictions and insights</p>
          </div>
        </div>
      </section>

      {/* Products Section */}
      <section className="ai-products-section" id="products">
        <h2>See AI in Action</h2>
        <div className="ai-product-showcase">
          <div className="ai-product-main">
            <div className="ai-product-header">
              <div className="ai-product-title">
                <h3>Entrepreneurship AI Companion</h3>
                <span className="ai-product-badge">Premium</span>
              </div>
              <p className="ai-product-description">
                Your intelligent partner for business growth and success. Powered by advanced AI to streamline your entrepreneurial journey.
              </p>
            </div>
            
            <div className="ai-product-features-container">
              <div className="ai-features-column">
                <h4>Smart Business Tools</h4>
                <div className="ai-features-list">
                  <div className="ai-feature-item">
                    <span className="ai-feature-icon">📋</span>
                    <div className="ai-feature-content">
                      <span className="ai-feature-title">Task Management</span>
                      <p>Intelligent task prioritization and scheduling</p>
                    </div>
                  </div>
                  <div className="ai-feature-item">
                    <span className="ai-feature-icon">💡</span>
                    <div className="ai-feature-content">
                      <span className="ai-feature-title">Business Advice</span>
                      <p>AI-powered insights and recommendations</p>
                    </div>
                  </div>
                  <div className="ai-feature-item">
                    <span className="ai-feature-icon">⚡</span>
                    <div className="ai-feature-content">
                      <span className="ai-feature-title">Query Handling</span>
                      <p>Real-time response to business queries</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="ai-features-column">
                <h4>Advanced Analytics</h4>
                <div className="ai-features-list">
                  <div className="ai-feature-item">
                    <span className="ai-feature-icon">📊</span>
                    <div className="ai-feature-content">
                      <span className="ai-feature-title">Data Analysis</span>
                      <p>Comprehensive business metrics and reporting</p>
                    </div>
                  </div>
                  <div className="ai-feature-item">
                    <span className="ai-feature-icon">💰</span>
                    <div className="ai-feature-content">
                      <span className="ai-feature-title">Expense Tracking</span>
                      <p>Smart financial monitoring and insights</p>
                    </div>
                  </div>
                  <div className="ai-feature-item">
                    <span className="ai-feature-icon">🔍</span>
                    <div className="ai-feature-content">
                      <span className="ai-feature-title">Market Research</span>
                      <p>AI-driven market analysis and trends</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ai-product-footer">
              <div className="ai-product-support">
                <span className="ai-feature-icon">🌐</span>
                <p>Multilingual Support Available in 20+ Languages</p>
              </div>
              <button 
                className="ai-product-button"
                onClick={() => window.open('https://entreai.datawiztech.com', '_blank')}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Achievements Section */}
      <section className="ai-achievements">
        <h2>Why Choose Us</h2>
        <div className="ai-achievement-cards">
          <div className="ai-achievement-card">
            <div className="ai-achievement-icon">🎯</div>
            <h3>99.9% Accuracy</h3>
            <p>Industry-leading precision in AI-driven solutions</p>
          </div>
          <div className="ai-achievement-card">
            <div className="ai-achievement-icon">📈</div>
            <h3>50M+ Predictions</h3>
            <p>Trusted by businesses worldwide for critical decisions</p>
          </div>
          <div className="ai-achievement-card">
            <div className="ai-achievement-icon">🤝</div>
            <h3>1000+ Clients</h3>
            <p>Empowering businesses across 30+ countries</p>
          </div>
          <div className="ai-achievement-card">
            <div className="ai-achievement-icon">⚡</div>
            <h3>24/7 Support</h3>
            <p>Round-the-clock expert assistance</p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="ai-footer">
        <div className="ai-footer-content">
          <div className="ai-footer-section">
            <h4>DATA<span className="ai-highlight">WIZTECH</span></h4>
            <p>Transforming the future with AI</p>
          </div>
          <div className="ai-footer-section">
            <h4>Contact</h4>
            <p>Email: info@datawiztech.com</p>
            
          </div>
          <div className="ai-footer-section">
            <h4>Follow Us</h4>
            <div className="ai-social-links">
              <a href="#twitter">Twitter</a>
              <a href="#linkedin">LinkedIn</a>
              <a href="#github">GitHub</a>
            </div>
          </div>
        </div>
        <div className="ai-footer-bottom">
          <p>&copy; 2024 DATAWIZTECH. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default AISolution;
