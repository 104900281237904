import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Consultancy.css';
import Header from "../Header/Header";
import { toast } from 'react-hot-toast';
import ActionLoader from '../Loader/ActionLoader';

const Consultancy = () => {
  const [active, setActive] = useState("consultancy");
  const [searchLoading, setSearchLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSearchLoading(true);
    const formData = new FormData(e.target);
    try {
      // Add your API endpoint here
      const response = await fetch('/api/consultation', {
        method: 'POST',
        body: formData
      });
      if (response.ok) {
        toast.success('Consultation request submitted successfully!');
        e.target.reset();
      } else {
        toast.error('Failed to submit consultation request');
      }
    } catch (error) {
      toast.error('Failed to submit consultation request');
      console.error('Error:', error);
    } finally {
      setSearchLoading(false);
    }
  };

  return (
    <div className="con-container">
      <Header active={active} />
      {/* Navigation */}
 
      {/* Hero Section */}
      <section className="con-hero" id="services">
        <div className="con-hero-content">
          <div className="con-hero-title">
            <h1>Expert Consultancy Services</h1>
            <div className="con-hero-subtitle">
              Leverage our deep expertise to solve your toughest challenges 
              and unlock growth opportunities.
            </div>
          </div>
          <div className="con-hero-description">
            Our team of seasoned professionals is here to guide you every step of the way.
          </div>
          <button 
            className="con-cta-button"
            onClick={() => {
              document.getElementById('consultation-form').scrollIntoView({ 
                behavior: 'smooth',
                block: 'center'
              });
            }}
          >
            Schedule Consultation
            <span className="con-button-icon">→</span>
          </button>
        </div>
      </section>

      {/* Services Grid */}
      <section className="con-services">
        <h2>What We Offer</h2>
        <div className="con-services-grid">
          <div className="con-service-card">
            <div className="con-service-icon">🔍</div>
            <h3>Consultancy</h3>
            <p>Tailored consultancy services to solve complex business problems and achieve sustainable growth.</p>
          </div>
          <div className="con-service-card">
            <div className="con-service-icon">📊</div>
            <h3>Research & Field Data Survey</h3>
            <p>Conduct in-depth research and field surveys to gather valuable insights that drive informed decision-making.</p>
          </div>
          <div className="con-service-card">
            <div className="con-service-icon">📈</div>
            <h3>Data Analytics & Visualization</h3>
            <p>Transform raw data into actionable insights with advanced analytics and visually compelling dashboards.</p>
          </div>
          <div className="con-service-card">
            <div className="con-service-icon">⚡</div>
            <h3>Business Automation</h3>
            <p>Automate repetitive business processes to improve efficiency and reduce operational costs.</p>
          </div>
          <div className="con-service-card">
            <div className="con-service-icon">🎯</div>
            <h3>Machine Learning & AI Solutions</h3>
            <p>Leverage the power of AI and machine learning to create predictive models and intelligent systems.</p>
          </div>
          <div className="con-service-card">
            <div className="con-service-icon">☁️</div>
            <h3>Cloud Service Integration</h3>
            <p>Integrate and optimize cloud-based services to scale operations and improve collaboration across teams.</p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="con-testimonials">
        <h2>What Our Clients Say</h2>
        <div className="con-testimonials-grid">
          <div className="con-testimonial-card">
            <p>"The insights we gained from their consultancy transformed our business operations. Highly recommend their services!"</p>
            <div className="con-testimonial-author">
              <h4>Jane Doe</h4>
              <p>CEO of Corp Ltd</p>
            </div>
          </div>
          <div className="con-testimonial-card">
            <p>"Their financial advisory service helped us streamline costs and boost revenue by 30%. Simply phenomenal!"</p>
            <div className="con-testimonial-author">
              <h4>Esther Aadeniyi </h4>
              <p>CFO of Accelerate Ltd</p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="con-contact" id="consultation-form">
        <div className="con-contact-wrapper">
          <div className="con-contact-content">
            <div className="con-contact-text">
              <h2>Ready to Transform Your Business?</h2>
              <p>Join the hundreds of businesses that have revolutionized their operations with our expert AI consultancy services.</p>
            </div>
            <div className="con-contact-actions">
              <form className="consultation-form p-4 bg-white rounded-3 shadow-sm" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Full Name"
                    required 
                  />
                </div>
                <div className="mb-3">
                  <input 
                    type="email" 
                    className="form-control" 
                    placeholder="Email Address"
                    required 
                  />
                </div>
                <div className="mb-3">
                  <select className="form-select" required>
                    <option value="">Select Service</option>
                    <option value="consultancy">Consultancy</option>
                    <option value="research">Research & Field Data Survey</option>
                    <option value="analytics">Data Analytics & Visualization</option>
                    <option value="automation">Business Automation</option>
                    <option value="ai">Machine Learning & AI Solutions</option>
                    <option value="cloud">Cloud Service Integration</option>
                  </select>
                </div>
                <div className="mb-3">
                  <textarea 
                    className="form-control" 
                    rows="4" 
                    placeholder="Tell us about your project"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="con-cta-button w-100">
                  {searchLoading ? (
                    <ActionLoader />
                  ) : (
                    <>
                      Schedule Consultation
                      <span className="con-button-icon">→</span>
                    </>
                  )}
                </button>
              </form>

              <div className="con-contact-divider mt-4">
                <span>or</span>
              </div>
              
              <div className="con-contact-info text-center mt-4">
                <div className="con-contact-method">
                  <span className="con-contact-icon">✉️</span>
                  <a href="mailto:info@datawiztech.com">info@datawiztech.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="con-footer">
        <div className="con-footer-content">
          <div className="con-footer-section">
            <h4>AI<span className="con-highlight">Vision</span></h4>
            <p>Expert AI Consultancy Services</p>
          </div>
          <div className="con-footer-section">
            <h4>Contact</h4>
            <p>Email: info@datawiztech.com</p>
            {/* <p>Phone: (555) 234-5678</p> */}
          </div>
          <div className="con-footer-section">
            <h4>Follow Us</h4>
            <div className="con-social-links">
              <a href="#linkedin">LinkedIn</a>
              <a href="#twitter">Twitter</a>
              <a href="#github">GitHub</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Consultancy; 