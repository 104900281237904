import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import HeroDwt from "./HeroDwt";
import BrandsDwt from "./Brands";
import CoursesDwt from "./CoursesDwt";
import WhyCourse from "./WhyCourse";
import LearningPath from "./LearningPath";
import LearningPathSeven from "./LearningPathSeven";
// import Testimonials from "./Testimonials";
import LearningPathsSix from "./LearningPathsSix";

const Academy = () => {
  const [active, setActive] = useState("academy");

  return (
    <div className="academy-wrapper overflow-hidden">
      <Header active={active} />
      <div className="container px-0">
        <HeroDwt />
        <BrandsDwt />
        <CoursesDwt />
        <LearningPathSeven />
        <LearningPath />
        <WhyCourse />
        <LearningPathsSix />
      </div>
    </div>
  );
};

export default Academy;
