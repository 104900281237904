import CourseImage1 from '../image/coursesCards/6.png';  // Import the image directly
import CourseImage2 from '../image/coursesCards/9.png';
import CourseImage3 from '../image/coursesCards/1.png';
import CourseImage4 from '../image/coursesCards/11.png';
import CourseImage5 from '../image/coursesCards/avatar-1.png';


export const coursesData = [
  {
    id: 1,
    imageSrc: CourseImage1,  // Use the imported image variable
    authorImageSrc: CourseImage5,
    title: "Data Analytics for Finance Professionals 1",
    rating: 4.9,
    ratingCount: 200,
    lessonCount: 6,
    duration: 1320,
    level: "Beginner",
    originalPrice: 250000,
    discountedPrice: 150000,
    paid: true,
    category: "Design",
    state: "Popular",
    language: "French",
    authorName: "DataWizTech",
    viewStatus: "Good",
    difficulty: "Easy",
    desc: "Master the fundamentals of data analytics tailored for finance professionals. Learn to analyze financial data, create insights, and drive decision-making using practical tools and techniques."
  },
  {
    id: 2,
    imageSrc: CourseImage2,  // Use the imported image variable
    authorImageSrc: CourseImage5,
    title: "Complete Python Bootcamp From Zero to Hero in Python",
    rating: 4.7,
    ratingCount: 511,
    lessonCount: 6,
    duration: 410,
    level: "Expert",
    originalPrice: 300000,
    discountedPrice: 200000,
    paid: true,
    category: "Programming",
    language: "German",
    authorName: "DataWizTech",
    popular: true,
    new: true,
    bestSeller: true,
    state: "Featured",
    viewStatus: "Low",
    difficulty: "Easy",
    desc: "Complete Python course, from beginner to advanced concepts."
  },


  {
    id: 3,
    imageSrc: CourseImage1,  // Use the imported image variable
    authorImageSrc: CourseImage5,
    title: "Data Analytics",
    rating: 4.8,
    ratingCount: 1523,
    lessonCount: 12,
    duration: 1450,
    level: "Intermediate",
    originalPrice: 500000,
    discountedPrice: 300000,
    language: "English",
    authorName: "DataWizTech",
    paid: true,
    category: "Data Science",
    state: "Trending",
    viewStatus: "Excellent",
    difficulty: "Moderate",
    desc: "Comprehensive guide to mastering data analytics, covering key techniques, tools, and real-world applications to turn data into actionable insights."
  },






  
  {
    id: 4,
    imageSrc: CourseImage3,  // Use the imported image variable
    authorImageSrc: CourseImage5,
    title: "The Ultimate Drawing Course Beginner to Advanced",
    rating: 4.2,
    ratingCount: 1991,
    lessonCount: 6,
    duration: 1020,
    level: "Expert",
    originalPrice: 179,
    discountedPrice: 99,
    language: "English",
    authorName: "Jacob Jones",
    paid: true,
    category: "Art",
    state: "Trending",
    viewStatus: "Good",
    difficulty: "Easy",
    desc: "Comprehensive guide to mastering drawing skills."
  },
  {
    id: 5,
    imageSrc: CourseImage4,  // Use the imported image variable
    authorImageSrc: CourseImage5,
    title: "Photography Masterclass: A Complete Guide to Photography",
    rating: 3.8,
    ratingCount: 1991,
    lessonCount: 6,
    duration: 250,
    level: "Intermediate",
    originalPrice: 169,
    discountedPrice: 79,
    paid: true,
    category: "Photography",
    language: "French",
    authorName: "Robert Fox",
    state: "Popular",
    viewStatus: "Low",
    difficulty: "Medium",
    desc: "Master the art of photography from basics to advanced."
  }
];


// export const coursesData = [
//     {
//         "id": 1,
//         "imageSrc": "/assets/img/coursesCards/6.png",
//         "authorImageSrc": "/assets/img/general/avatar-1.png",
//         "title": "Learn Figma - UI/UX Design Essential Training",
//         "rating": 4.3,
//         "ratingCount": 1991,
//         "lessonCount": 6,
//         "duration": 1320,
//         "level": "Beginner",
//         "originalPrice": 199,
//         "discountedPrice": 79,
//         "paid": true,
//         "category": "Design",
//         "state": "Popular",
//         "languange": "French",
//         "authorName": "Jane Cooper",
//         "viewStatus": "Good",
//         "difficulty": "Easy",
//         "desc": "Introductory course on web hosting, domain registration, and how you can easily publish and edit your website online."
//     },
//     {
//         "id": 2,
//         "imageSrc": "/assets/img/coursesCards/9.png",
//         "authorImageSrc": "/assets/img/general/avatar-1.png",
//         "title": "Complete Python Bootcamp From Zero to Hero in Python",
//         "rating": 4.7,
//         "ratingCount": 1991,
//         "lessonCount": 6,
//         "duration": 410,
//         "level": "Expert",
//         "originalPrice": 189,
//         "discountedPrice": 89,
//         "paid": true,
//         "category": "Programming",
//         "languange": "German",
//         "authorName": "Jenny Wilson",
//         "popular": true,
//         "new": true,
//         "bestSeller": true,
//         "state": "Fetured",
//         "viewStatus": "Low",
//         "difficulty": "Easy",
//         "desc": "Introductory course on web hosting, domain registration, and how you can easily publish and edit your website online."
//     },
//     {
//         "id": 3,
//         "imageSrc": "/assets/img/coursesCards/6.png",
//         "authorImageSrc": "/assets/img/general/avatar-1.png",
//         "title": "Angular - The Complete Guide (2022 Edition)",
//         "rating": 4.5,
//         "ratingCount": 1991,
//         "lessonCount": 6,
//         "duration": 1220,
//         "level": "Intermediate",
//         "originalPrice": 249,
//         "discountedPrice": 129,
//         "languange": "Italian",
//         "authorName": "Albert Flores",
//         "paid": true,
//         "category": "Programming",
//         "state": "Trending",
//         "viewStatus": "Great",
//         "difficulty": "Easy",
//         "desc": "Introductory course on web hosting, domain registration, and how you can easily publish and edit your website online."
//     },
//     {
//         "id": 4,
//         "imageSrc": "/assets/img/coursesCards/1.png",
//         "authorImageSrc": "/assets/img/general/avatar-1.png",
//         "title": "The Ultimate Drawing Course Beginner to Advanced",
//         "rating": 4.2,
//         "ratingCount": 1991,
//         "lessonCount": 6,
//         "duration": 1020,
//         "level": "Expert",
//         "originalPrice": 179,
//         "discountedPrice": 99,
//         "languange": "English",
//         "authorName": "Jacob Jones",
//         "paid": true,
//         "category": "Art",
//         "state": "Trending",
//         "viewStatus": "Good",
//         "difficulty": "Easy",
//         "desc": "Introductory course on web hosting, domain registration, and how you can easily publish and edit your website online."
//     },
//     {
//         "id": 5,
//         "imageSrc": "/assets/img/coursesCards/11.png",
//         "authorImageSrc": "/assets/img/general/avatar-1.png",
//         "title": "Photography Masterclass: A Complete Guide to Photography",
//         "rating": 3.8,
//         "ratingCount": 1991,
//         "lessonCount": 6,
//         "duration": 250,
//         "level": "Intermediate",
//         "originalPrice": 169,
//         "discountedPrice": 79,
//         "paid": true,
//         "category": "Photography",
//         "languange": "French",
//         "authorName": "Robert Fox",
//         "state": "Popular",
//         "viewStatus": "Low",
//         "difficulty": "Meduium",
//         "desc": "Introductory course on web hosting, domain registration, and how you can easily publish and edit your website online."
//     }
   
    
   
//   ]
  
  export const catagories = [
    "All Categories",
    "Animation",
    "Design",
    "Photography",
    "Art",
    "Programming",
  
    "Writing",
  ];
  
  export const courseStates = ["All", "Fetured", "Popular", "Trending"];
  
  export const viewStatus = ["All", "Great", "Good", "Medium", "Low"];
  
  export const resentCourses = [
    {
      id: 1,
      imageSrc: "/assets/img/dashboard/recent-courses/1.png",
      title: "Complete Python Bootcamp From Zero to Hero in Python",
      author: "Ali Tufan",
      authorImg: `/assets/img/general/avatar-1.png`,
      lessonCount: 6,
      duration: 236,
    },
    {
      id: 2,
      imageSrc: "/assets/img/dashboard/recent-courses/2.png",
      title: "The Ultimate Drawing Course Beginner to Advanced",
      author: "Ali Tufan",
      authorImg: `/assets/img/general/avatar-1.png`,
      lessonCount: 6,
      duration: 236,
    },
    {
      id: 3,
      imageSrc: "/assets/img/dashboard/recent-courses/3.png",
      title: "Instagram Marketing 2021: Complete Guide To Instagram Growth",
      author: "Ali Tufan",
      authorImg: `/assets/img/general/avatar-1.png`,
      lessonCount: 6,
      duration: 236,
    },
  ];
  
  export const allCategories = [
    "All",
    "Design",
    "Programming",
    "Art",
    "Photography",
    "Animation",
  
    "Writing",
  ];
  
  export const difficulty = ["All", "Hard", "Meduium", "Easy"];
  
  export const tags = [
    { id: 1, href: "#", name: "Courses" },
    { id: 2, href: "#", name: "Learn" },
    { id: 3, href: "#", name: "Online" },
    { id: 4, href: "#", name: "Education" },
    { id: 5, href: "#", name: "LMS" },
    { id: 6, href: "#", name: "Training" },
  ];
  
  export const instractorNames = [
    { id: 1, title: "Jane Cooper",  },
    { id: 2, title: "Jenny Wilson",  },
    { id: 3, title: "Robert Fox",  },
    { id: 4, title: "Jacob Jones",  },
    { id: 5, title: "Albert Flores",  },
  ];
  
  export const languages = [
    { id: 1, title: "English",  },
    { id: 2, title: "French",  },
    { id: 3, title: "German", },
    { id: 4, title: "Italian", },
  ];
  
  export const levels = [
   
    { id: 2, title: "Beginner", },
    { id: 3, title: "Intermediate", },
    { id: 4, title: "Expert",  },
  ];
  export const prices = [
    { id: 1, title: "All", },
    { id: 2, title: "Free", },
    { id: 3, title: "Paid", },
  ];
  
  export const rating = [
    { id: 1, star: 5, text: "4.5 & up",  range: [4.5, 5] },
    { id: 2, star: 5, text: "4.0 & up",  range: [4, 5] },
    { id: 3, star: 5, text: "3.5 & up",  range: [3.5, 5] },
    { id: 4, star: 5, text: "3.0 & up",  range: [3, 5] },
  ];
  
  export const categories = [
    { id: 1, title: "Art",  },
    { id: 2, title: "Animation",  },
    { id: 3, title: "Design",  },
    { id: 4, title: "Photography",  },
    { id: 5, title: "Programming",  },
  
    { id: 6, title: "Writing", },
  ];
  
  export const duration = [
    { id: 1, title: "Less than 4 hours", range: [0, 239], },
    { id: 2, title: "4 - 7 hours", range: [240, 419],  },
    { id: 3, title: "7 - 18 hours", range: [420, 1079],},
    { id: 4, title: "20 + Hours", range: [1080, 5000], },
  ];
  
  export const sortingOptions = [
      'Default',
      'Rating (asc)',
      'Rating (dsc)',
      'Price (asc)',
      'Price (dsc)',
      'Duration (asc)',
      'Duration (dsc)',
  ]