

export default {
    "data_by_country": [
      {
        "indicator_name": "Total Revenue  (₦' Million)",
        "country_name": "Nigeria",
        "country_code": "NGA",
        "source": "Central Bank of Nigeria (CBN)",
        "price_per_year": 0,
        "data_measurement": " (₦' Million)",
        "periodicity": "Quarterly",
        "data_short_description": "otal revenue is the total receipts a seller can obtain from selling goods or services to buyers.",
        "data_long_description": "Total revenue in economics refers to the total receipts from sales of a given quantity of goods or services. It is the total income of a business and is calculated by multiplying the quantity of goods sold by the price of the goods.",
        "payment_status": false,
        "price_per_year_(dollars)": 10,
        "_2000_q1": 778.494,
        "_2000_q2": 776.6906,
        "2000_q3": 817.971,
        "_2000_q2.1": 960.972,
        "_2000_q3": 846.684,
        "_2000_q4": 774.565,
        "_2001_q1": 1021.311,
        "_2001_q2": 719.635,
        "2001_q3": 805.298,
        "_2001_q2.1": 824.848,
        "_2001_q3": 888.698,
        "_2001_q4": 768.927,
        "_2002_q1": 970.017,
        "_2002_q2": 498.54,
        "2002_q3": 994.02,
        "_2002_q2.1": 746.988,
        "_2002_q3": 550.216,
        "_2002_q4": 678.562,
        "_2003_q1": 938.705,
        "_2003_q2": 805.053,
        "2003_q3": 1051.2980521693,
        "_2003_q2.1": 648.231,
        "_2003_q3": 830.932,
        "_2003_q4": 778.494,
        "_2004_q1": 776.6906,
        "_2004_q2": 817.971,
        "2004_q3": 960.972,
        "_2004_q2.1": 846.684,
        "_2004_q3": 774.565,
        "_2004_q4": 1021.311,
        "_2005_q1": 719.635,
        "_2005_q2": 805.298,
        "2005_q3": 824.848,
        "_2005_q2.1": 888.698,
        "_2005_q3": 768.927,
        "_2005_q4": 970.017,
        "_2006_q1": 498.54,
        "_2006_q2": 994.02,
        "2006_q3": 746.988,
        "_2006_q2.1": 550.216,
        "_2006_q3": 678.562,
        "_2006_q4": 938.705,
        "_2007_q1": 805.053,
        "_2007_q2": 1051.2980521693,
        "2007_q3": 648.231,
        "_2007_q2.1": 830.932,
        "_2007_q3": 913.8680149055,
        "_2007_q4": 945.7994096258,
        "_2008_q1": 977.7308043462,
        "_2008_q2": 1009.6621990665,
        "2008_q3": 1041.5935937868,
        "_2008_q2.1": 1073.5249885072,
        "_2008_q3": 1105.4563832275,
        "_2008_q4": 1137.3877779478,
        "_2009_q1": 1169.3191726681,
        "_2009_q2": 1201.2505673885,
        "2009_q3": 1233.1819621088,
        "_2009_q2.1": 778.494,
        "_2009_q3": 776.6906,
        "_2009_q4": 817.971,
        "_2010_q1": 960.972,
        "_2010_q2": 846.684,
        "2010_q3": 774.565,
        "2010_q4": 1021.311,
        "_2011_q1": 719.635,
        "_2011_q2": 805.298,
        "_2011_q3": 824.848,
        "_2011_q4": 888.698,
        "_2010_q1.1": 768.927,
        "_2010_q2.1": 970.017,
        "_2010_q3": 498.54,
        "_2010_q4": 994.02,
        "_2011_q1.1": 746.988,
        "_2011_q2.1": 550.216,
        "2011_q3": 678.562,
        "2011_q4": 938.705,
        "_2012_q1": 805.053,
        "_2012_q2": 1051.2980521693,
        "_2012_q3": 648.231,
        "_2012_q4": 830.932,
        "_2011_q1.2": null,
        "_2011_q2.2": null,
        "_2011_q3.1": null,
        "_2011_q4.1": null,
        "_2012_q1.1": null,
        "_2012_q2.1": null,
        "2012_q3": null,
        "2012_q4": null,
        "_2013_q1": null,
        "_2013_q2": null,
        "_2013_q3": null,
        "_2013_q4": null,
        "_2012_q1.2": 778.494,
        "_2012_q2.2": 776.6906,
        "_2012_q3.1": 817.971,
        "_2012_q4.1": 960.972,
        "_2013_q1.1": 846.684,
        "_2013_q2.1": 774.565,
        "2013_q3": 1021.311,
        "2013_q4": 719.635,
        "_2014_q1": 805.298,
        "_2014_q2": 824.848,
        "_2014_q3": 888.698,
        "_2014_q4": 768.927,
        "_2015_q1": 970.017,
        "_2015_q2": 498.54,
        "_2015_q3": 994.02,
        "_2015_q4": 746.988,
        "_2016_q1": 550.216,
        "_201_q2": 678.562,
        "_2016_q3": 938.705,
        "_2016_q4": 805.053,
        "_2017_q1_": 1051.2980521693,
        "_2017_q2_": 648.231,
        "_2017_q3_": 830.932,
        "_2017_q4_": 941.74,
        "_2018_q1_": 835.1155899886,
        "_2018_q2_": 1085.9910790509,
        "_2018_q3_": 946.779,
        "_2018_q4_": 1047.748,
        "_2019_q1_": 1137.34,
        "_2019_q2_": 943.17,
        "_2019_q3_": 1217.51,
        "2019_q4_": 1391.27,
        "_2020_q1_": 1431.355,
        "_2020_q2_": 1534.968,
        "_2020_q3_": 1638.581
      },
      {
        "indicator_name": "Total Expenditure  (₦' Million)",
        "country_name": "South Asia",
        "country_code": "SAS",
        "source": "Central Bank of Nigeria (CBN)",
        "price_per_year": 0,
        "data_measurement": " (₦' Million)",
        "periodicity": "Quarterly",
        "data_short_description": "Total expenditure is price times quantity. ",
        "data_long_description": "Total expenditure is price times quantity. Because price and quantity change in opposite directions along a demand curve, changes in total expenditure depend on the relative changes in price and quantity.",
        "payment_status": false,
        "price_per_year_(dollars)": 2,
        "_2000_q1": 865.039,
        "_2000_q2": 971.4006,
        "2000_q3": 1100.51,
        "_2000_q2.1": 1168.288,
        "_2000_q3": 1000.699,
        "_2000_q4": 1079.066,
        "_2001_q1": 1139.856,
        "_2001_q2": 1347.653,
        "2001_q3": 1008,
        "_2001_q2.1": 869.808,
        "_2001_q3": 1152.477,
        "_2001_q4": 1093.13,
        "_2002_q1": 1160.534,
        "_2002_q2": 984.488,
        "2002_q3": 1139.529,
        "_2002_q2.1": 1482.814,
        "_2002_q3": 1082.442,
        "_2002_q4": 1407.382,
        "_2003_q1": 1344.707,
        "_2003_q2": 1485.885,
        "2003_q3": 1842.545,
        "_2003_q2.1": 1303.117,
        "_2003_q3": 1592.5,
        "_2003_q4": 865.039,
        "_2004_q1": 971.4006,
        "_2004_q2": 1100.51,
        "2004_q3": 1168.288,
        "_2004_q2.1": 1000.699,
        "_2004_q3": 1079.066,
        "_2004_q4": 1139.856,
        "_2005_q1": 1347.653,
        "_2005_q2": 1008,
        "2005_q3": 869.808,
        "_2005_q2.1": 1152.477,
        "_2005_q3": 1093.13,
        "_2005_q4": 1160.534,
        "_2006_q1": 984.488,
        "_2006_q2": 1139.529,
        "2006_q3": 1482.814,
        "_2006_q2.1": 1082.442,
        "_2006_q3": 1407.382,
        "_2006_q4": 1344.707,
        "_2007_q1": 1485.885,
        "_2007_q2": 1842.545,
        "2007_q3": 1303.117,
        "_2007_q2.1": 1592.5,
        "_2007_q3": 1696.8657142857,
        "_2007_q4": 1761.8472142857,
        "_2008_q1": 1826.8287142857,
        "_2008_q2": 1891.8102142857,
        "2008_q3": 1956.7917142857,
        "_2008_q2.1": 2021.7732142857,
        "_2008_q3": 2086.7547142857,
        "_2008_q4": 2151.7362142857,
        "_2009_q1": 2216.7177142857,
        "_2009_q2": 2281.6992142857,
        "2009_q3": 2346.6807142857,
        "_2009_q2.1": 865.039,
        "_2009_q3": 971.4006,
        "_2009_q4": 1100.51,
        "_2010_q1": 1168.288,
        "_2010_q2": 1000.699,
        "2010_q3": 1079.066,
        "2010_q4": 1139.856,
        "_2011_q1": 1347.653,
        "_2011_q2": 1008,
        "_2011_q3": 869.808,
        "_2011_q4": 1152.477,
        "_2010_q1.1": 1093.13,
        "_2010_q2.1": 1160.534,
        "_2010_q3": 984.488,
        "_2010_q4": 1139.529,
        "_2011_q1.1": 1482.814,
        "_2011_q2.1": 1082.442,
        "2011_q3": 1407.382,
        "2011_q4": 1344.707,
        "_2012_q1": 1485.885,
        "_2012_q2": 1842.545,
        "_2012_q3": 1303.117,
        "_2012_q4": 1592.5,
        "_2011_q1.2": null,
        "_2011_q2.2": null,
        "_2011_q3.1": null,
        "_2011_q4.1": null,
        "_2012_q1.1": null,
        "_2012_q2.1": null,
        "2012_q3": null,
        "2012_q4": null,
        "_2013_q1": null,
        "_2013_q2": null,
        "_2013_q3": null,
        "_2013_q4": null,
        "_2012_q1.2": 865.039,
        "_2012_q2.2": 971.4006,
        "_2012_q3.1": 1100.51,
        "_2012_q4.1": 1168.288,
        "_2013_q1.1": 1000.699,
        "_2013_q2.1": 1079.066,
        "2013_q3": 1139.856,
        "2013_q4": 1347.653,
        "_2014_q1": 1008,
        "_2014_q2": 869.808,
        "_2014_q3": 1152.477,
        "_2014_q4": 1093.13,
        "_2015_q1": 1160.534,
        "_2015_q2": 984.488,
        "_2015_q3": 1139.529,
        "_2015_q4": 1482.814,
        "_2016_q1": 1082.442,
        "_201_q2": 1407.382,
        "_2016_q3": 1344.707,
        "_2016_q4": 1485.885,
        "_2017_q1_": 1842.545,
        "_2017_q2_": 1303.117,
        "_2017_q3_": 1592.5,
        "_2017_q4_": 1910.355,
        "_2018_q1_": 2148.472,
        "_2018_q2_": 1576.337,
        "_2018_q3_": 1868.622,
        "_2018_q4_": 1944.595,
        "_2019_q1_": 2439.63,
        "_2019_q2_": 2108.78,
        "_2019_q3_": 2349.66,
        "2019_q4_": 2556.2,
        "_2020_q1_": 2511.215,
        "_2020_q2_": 2570.274,
        "_2020_q3_": 2629.333
      },
      {
        "indicator_name": "FG Fiscal (Deficit)/Surplus  (₦' Million)",
        "country_name": "Togo",
        "country_code": "TGO",
        "source": "Central Bank of Nigeria (CBN)",
        "price_per_year": 0,
        "data_measurement": " (₦' Million)",
        "periodicity": "Quarterly",
        "data_short_description": "A government budget is a financial statement presenting the government's proposed revenues and spending for a financial year.",
        "data_long_description": "A balanced budget exists once expenditures equal income. The Clinton administration eliminated a large budget deficit, resulting in a surplus. A surplus is a positive value and is the sum by which government revenues are greater than government spending during a set period, usually a fiscal year.",
        "payment_status": false,
        "price_per_year_(dollars)": 3,
        "_2000_q1": -86.545,
        "_2000_q2": -194.71,
        "2000_q3": -282.539,
        "_2000_q2.1": -207.316,
        "_2000_q3": -154.015,
        "_2000_q4": -304.501,
        "_2001_q1": -118.545,
        "_2001_q2": -628.018,
        "2001_q3": -202.702,
        "_2001_q2.1": -44.96,
        "_2001_q3": -263.779,
        "_2001_q4": -324.203,
        "_2002_q1": -190.517,
        "_2002_q2": -485.948,
        "2002_q3": -145.509,
        "_2002_q2.1": -735.826,
        "_2002_q3": -532.226,
        "_2002_q4": -728.82,
        "_2003_q1": -406.002,
        "_2003_q2": -680.832,
        "2003_q3": -791.2469478307,
        "_2003_q2.1": -654.886,
        "_2003_q3": -761.568,
        "_2003_q4": -86.545,
        "_2004_q1": -194.71,
        "_2004_q2": -282.539,
        "2004_q3": -207.316,
        "_2004_q2.1": -154.015,
        "_2004_q3": -304.501,
        "_2004_q4": -118.545,
        "_2005_q1": -628.018,
        "_2005_q2": -202.702,
        "2005_q3": -44.96,
        "_2005_q2.1": -263.779,
        "_2005_q3": -324.203,
        "_2005_q4": -190.517,
        "_2006_q1": -485.948,
        "_2006_q2": -145.509,
        "2006_q3": -735.826,
        "_2006_q2.1": -532.226,
        "_2006_q3": -728.82,
        "_2006_q4": -406.002,
        "_2007_q1": -680.832,
        "_2007_q2": -791.2469478307,
        "2007_q3": -654.886,
        "_2007_q2.1": -761.568,
        "_2007_q3": -782.9976993802,
        "_2007_q4": -816.0478046599,
        "_2008_q1": -849.0979099396,
        "_2008_q2": -882.1480152192,
        "2008_q3": -915.1981204989,
        "_2008_q2.1": -948.2482257786,
        "_2008_q3": -981.2983310582,
        "_2008_q4": -1014.3484363379,
        "_2009_q1": -1047.3985416176,
        "_2009_q2": -1080.4486468972,
        "2009_q3": -1113.4987521769,
        "_2009_q2.1": -86.545,
        "_2009_q3": -194.71,
        "_2009_q4": -282.539,
        "_2010_q1": -207.316,
        "_2010_q2": -154.015,
        "2010_q3": -304.501,
        "2010_q4": -118.545,
        "_2011_q1": -628.018,
        "_2011_q2": -202.702,
        "_2011_q3": -44.96,
        "_2011_q4": -263.779,
        "_2010_q1.1": -324.203,
        "_2010_q2.1": -190.517,
        "_2010_q3": -485.948,
        "_2010_q4": -145.509,
        "_2011_q1.1": -735.826,
        "_2011_q2.1": -532.226,
        "2011_q3": -728.82,
        "2011_q4": -406.002,
        "_2012_q1": -680.832,
        "_2012_q2": -791.2469478307,
        "_2012_q3": -654.886,
        "_2012_q4": -761.568,
        "_2011_q1.2": null,
        "_2011_q2.2": null,
        "_2011_q3.1": null,
        "_2011_q4.1": null,
        "_2012_q1.1": null,
        "_2012_q2.1": null,
        "2012_q3": null,
        "2012_q4": null,
        "_2013_q1": null,
        "_2013_q2": null,
        "_2013_q3": null,
        "_2013_q4": null,
        "_2012_q1.2": -86.545,
        "_2012_q2.2": -194.71,
        "_2012_q3.1": -282.539,
        "_2012_q4.1": -207.316,
        "_2013_q1.1": -154.015,
        "_2013_q2.1": -304.501,
        "2013_q3": -118.545,
        "2013_q4": -628.018,
        "_2014_q1": -202.702,
        "_2014_q2": -44.96,
        "_2014_q3": -263.779,
        "_2014_q4": -324.203,
        "_2015_q1": -190.517,
        "_2015_q2": -485.948,
        "_2015_q3": -145.509,
        "_2015_q4": -735.826,
        "_2016_q1": -532.226,
        "_201_q2": -728.82,
        "_2016_q3": -406.002,
        "_2016_q4": -680.832,
        "_2017_q1_": -791.2469478307,
        "_2017_q2_": -654.886,
        "_2017_q3_": -761.568,
        "_2017_q4_": -968.615,
        "_2018_q1_": -1313.3564100114,
        "_2018_q2_": -490.3459209491,
        "_2018_q3_": -921.843,
        "_2018_q4_": -896.847,
        "_2019_q1_": -1302.29,
        "_2019_q2_": -1165.61,
        "_2019_q3_": -1132.15,
        "2019_q4_": -1164.93,
        "_2020_q1_": -1079.86,
        "_2020_q2_": -1035.306,
        "_2020_q3_": -990.752
      }
    ],
    "error_data": {
      "0": {
        "country_code": "SAS",
        "country_name": "South Asia"
      }
    }
  };