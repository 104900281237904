import img1 from "../image/Learning/1.svg";
import img2 from "../image/Learning/2.svg";
import img3 from "../image/Learning/3.svg";
import img4 from "../image/Learning/4.svg";




export const learningPath = [
    {
      id: "item1",
      iconBg: "bg-green-2",
      iconSrc: "/assets/img/home-2/learning/icons/1.svg",
      title: "Leadership development",
      description:
        "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries",
    },
    {
      id: "item2",
      iconBg: "bg-purple-2",
      iconSrc: "/assets/img/home-2/learning/icons/2.svg",
      title: "Digital transformation",
      description:
        "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries",
    },
    {
      id: "item3",
      iconBg: "bg-orange-2",
      iconSrc: "/assets/img/home-2/learning/icons/3.svg",
      title: "Innovation & design thinking",
      description:
        "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries",
    },
  ];
  
//   export const learningPathTwo = [
//     {
//       id: 1,
//       icon: "/assets/img/home-4/learning/1.svg",
//       title: "6-12 Months Work Experience",
//       text: "Acquire real-world experience to boost your resume",
//       bgColor: "bg-green-3",
//     },
//     {
//       id: 2,
//       icon: "/assets/img/home-4/learning/2.svg",
//       title: "Job Reference Letter",
//       text: "Gain a valuable reference for your job applications",
//       bgColor: "bg-yellow-2",
//     },
//     {
//       id: 3,
//       icon: "/assets/img/home-4/learning/3.svg",
//       title: "Portfolio Creation",
//       text: "Showcase your work with a professional portfolio",
//       bgColor: "bg-blue-2",
//     },
//     {
//       id: 4,
//       icon: "/assets/img/home-4/learning/4.svg",
//       title: "Recommendation Letter",
//       text: "Get personalized recommendations from mentors",
//       bgColor: "bg-orange-3",
//     },
//   ];


export const learningPathTwo = [
    {
        id: 1,
        icon: img1,
        title: "6-12 Months Work Experience",
        text: "Acquire real-world experience to boost your resume",
        bgColor: "bg-green-3",
    },
    {
        id: 2,
        icon: img2,
        title: "Job Reference Letter",
        text: "Gain a valuable reference for your job applications",
        bgColor: "bg-yellow-2",
    },
    {
        id: 3,
        icon: img3,
        title: "Portfolio Creation",
        text: "Showcase your work with a professional portfolio",
        bgColor: "bg-blue-2",
    },
    {
        id: 4,
        icon: img4,
        title: "Recommendation Letter",
        text: "Get personalized recommendations from mentors",
        bgColor: "bg-orange-3",
    },
];


  
  export const learningPathFive = [
    {
      id: 1,
      title: "Learn with Experts",
      image: "/assets/img/home-5/learning/1.svg",
      description: "Grursus mal suada faci lisis that ipsum ameti consecte.",
    },
    {
      id: 2,
      title: "Learn Anything",
      image: "/assets/img/home-5/learning/2.svg",
      description: "Grursus mal suada faci lisis that ipsum ameti consecte.",
    },
    {
      id: 3,
      title: "Flexible Learning",
      image: "/assets/img/home-5/learning/3.svg",
      description: "Grursus mal suada faci lisis that ipsum ameti consecte.",
    },
    {
      id: 4,
      title: "Industrial Standart",
      image: "/assets/img/home-5/learning/4.svg",
      description: "Grursus mal suada faci lisis that ipsum ameti consecte.",
    },
  ];
  
  export const learningPathSix = [
    {
      id: 1,
      iconClass: "icon icon-rating-2 text-dark-1",
      title: "Learn anything",
      description:
        "The latest design trends meet hand-crafted templates in Sassio Collection.",
    },
    {
      id: 2,
      iconClass: "icon icon-online-learning-3 text-dark-1",
      title: "Learn together",
      description:
        "The latest design trends meet hand-crafted templates in Sassio Collection.",
    },
    {
      id: 3,
      iconClass: "icon icon-online-learning-2 text-dark-1",
      title: "Learn with experts",
      description:
        "The latest design trends meet hand-crafted templates in Sassio Collection.",
    },
    {
      id: 4,
      iconClass: "icon icon-access text-dark-1",
      title: "Life Time Access",
      description:
        "The latest design trends meet hand-crafted templates in Sassio Collection.",
    },
  ];
  
  export const learningfeatures = [
    {
      id: 1,
      title: "Experience authors",
    },
    {
      id: 2,
      title: "Easy to follow curriculum",
    },
    {
      id: 3,
      title: "Practical courses",
    },
    {
      id: 4,
      title: "Money-back guarantee",
    },
  ];
  
  export const features = [
    {
      id: 1,
      iconSrc: img1,
      title: "Hands-on Project",
      description:
        "Put your skills into practice through a hands-on final project",
    },
    {
      id: 2,
      iconSrc: img2,
      title: "Create Portfolio",
      description:
        "Receive feedback on your portfolio from industry experts",
    },
    {
      id: 3,
      iconSrc: img3,
      title: "Get Tech Certificate",
      description:
        "Celebrate your achievements with a tech certification",
    },
  ];
  
  export const learningJourney = [
    {
      id: 1,
      imageSrc: "/assets/img/home-5/learning/1.svg",
      title: "Learn with Experts",
      text: "Grursus mal suada faci lisis that ipsum ameti consecte.",
      delay: 1,
    },
    {
      id: 2,
      imageSrc: "/assets/img/home-5/learning/2.svg",
      title: "Learn Anything",
      text: "Grursus mal suada faci lisis that ipsum ameti consecte.",
      delay: 2,
    },
    {
      id: 3,
      imageSrc: "/assets/img/home-5/learning/3.svg",
      title: "Flexible Learning",
      text: "Grursus mal suada faci lisis that ipsum ameti consecte.",
      delay: 3,
    },
    {
      id: 4,
      imageSrc: "/assets/img/home-5/learning/4.svg",
      title: "Industrial Standart",
      text: "Grursus mal suada faci lisis that ipsum ameti consecte.",
      delay: 4,
    },
  ];
  