import React from "react";
import searchIcon from "../../../assets/images/frame-1-txZ.png";
import { Link } from 'react-router-dom';

const Find = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);  // Scroll to top when clicking the link
  };

  return (
    <div class="container-fluid find-section">
      <div class="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6 py-5">
            <p class="text-center find-section-title">
              Master Tech Skills, Land Remote Jobs
            </p>

            <p class="push-text">
              <p class="find-section-text text-center">
                We equip you with essential tech skills, real-world experience, and a job reference letter. You will also create a professional portfolio and receive personalized recommendations to significantly boost your career prospects.
              </p>
            </p>
            <div class="begin-search-container">
              <Link to="/academy" 
                style={{ textDecoration: 'none' }} 
                onClick={handleClick}
              >
                <div class="begin-search-btn px-4">
                  <p class="text-center begin-search mt-2">
                    Learn more
                  </p>
                </div>
              </Link>
            </div>
            <div class="auto-group-afl5-dMB-bg"></div>
        </div>
        <div className="col-lg-3"></div>
      </div>
    </div>
  );
};

export default Find;
