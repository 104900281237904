export const steps = [
    {
      id: 1,
      icon: "icon-online-learning-4 text-64 text-green-1",
      title: "01. Learn",
      text: "Access comprehensive courses in tech. Learn from industry experts through hands-on projects and real-world applications.",
    },
    {
      id: 2,
      icon: "icon-graduation-1 text-64 text-green-1",
      title: "02. Graduate",
      text: "Complete your coursework and earn industry-recognized certifications. Build a portfolio of projects that showcase your practical skills.",
    },
    {
      id: 3,
      icon: "icon-working-at-home-2 text-64 text-green-1",
      title: "03. Work",
      text: "Launch your career in tech with confidence. Apply your skills in high-demand roles across Africa's growing digital economy.",
    },
  ];
  
  export const stepsTwo = [
    {
      id: 1,
      imageSrc: "/assets/img/home-3/works/1.svg",
      text: "Browse courses from our expert contributors.",
    },
    {
      id: 2,
      imageSrc: "/assets/img/home-3/works/2.svg",
      text: "Purchase quickly and securely.",
    },
    {
      id: 3,
      imageSrc: "/assets/img/home-3/works/3.svg",
      text: "That’s it! Start learning right away.",
    },
  ];