
export default [
    {"country": "Afghanistan", "code": "+93", "short_code": "AF"},
    {"country": "Albania", "code": "+355", "short_code": "AL"},
    {"country": "Algeria", "code": "+213", "short_code": "DZ"},
    {"country": "Andorra", "code": "+376", "short_code": "AD"},
    {"country": "Angola", "code": "+244", "short_code": "AO"},
    {"country": "Antigua and Barbuda", "code": "+1-268", "short_code": "AG"},
    {"country": "Argentina", "code": "+54", "short_code": "AR"},
    {"country": "Armenia", "code": "+374", "short_code": "AM"},
    {"country": "Australia", "code": "+61", "short_code": "AU"},
    {"country": "Austria", "code": "+43", "short_code": "AT"},
    {"country": "Azerbaijan", "code": "+994", "short_code": "AZ"},
    {"country": "Bahamas", "code": "+1-242", "short_code": "BS"},
    {"country": "Bahrain", "code": "+973", "short_code": "BH"},
    {"country": "Bangladesh", "code": "+880", "short_code": "BD"},
    {"country": "Barbados", "code": "+1-246", "short_code": "BB"},
    {"country": "Belarus", "code": "+375", "short_code": "BY"},
    {"country": "Belgium", "code": "+32", "short_code": "BE"},
    {"country": "Belize", "code": "+501", "short_code": "BZ"},
    {"country": "Benin", "code": "+229", "short_code": "BJ"},
    {"country": "Bhutan", "code": "+975", "short_code": "BT"},
    {"country": "Bolivia", "code": "+591", "short_code": "BO"},
    {"country": "Bosnia and Herzegovina", "code": "+387", "short_code": "BA"},
    {"country": "Botswana", "code": "+267", "short_code": "BW"},
    {"country": "Brazil", "code": "+55", "short_code": "BR"},
    {"country": "Brunei", "code": "+673", "short_code": "BN"},
    {"country": "Bulgaria", "code": "+359", "short_code": "BG"},
    {"country": "Burkina Faso", "code": "+226", "short_code": "BF"},
    {"country": "Burundi", "code": "+257", "short_code": "BI"},
    {"country": "Cambodia", "code": "+855", "short_code": "KH"},
    {"country": "Cameroon", "code": "+237", "short_code": "CM"},
    {"country": "Canada", "code": "+1", "short_code": "CA"},
    {"country": "Cape Verde", "code": "+238", "short_code": "CV"},
    {"country": "Central African Republic", "code": "+236", "short_code": "CF"},
    {"country": "Chad", "code": "+235", "short_code": "TD"},
    {"country": "Chile", "code": "+56", "short_code": "CL"},
    {"country": "China", "code": "+86", "short_code": "CN"},
    {"country": "Colombia", "code": "+57", "short_code": "CO"},
    {"country": "Comoros", "code": "+269", "short_code": "KM"},
    {"country": "Congo", "code": "+242", "short_code": "CG"},
    {"country": "Costa Rica", "code": "+506", "short_code": "CR"},
    {"country": "Croatia", "code": "+385", "short_code": "HR"},
    {"country": "Cuba", "code": "+53", "short_code": "CU"},
    {"country": "Cyprus", "code": "+357", "short_code": "CY"},
    {"country": "Czech Republic", "code": "+420", "short_code": "CZ"},
    {"country": "Democratic Republic of the Congo", "code": "+243", "short_code": "CD"},
    {"country": "Denmark", "code": "+45", "short_code": "DK"},
    {"country": "Djibouti", "code": "+253", "short_code": "DJ"},
    {"country": "Dominica", "code": "+1-767", "short_code": "DM"},
    {"country": "Dominican Republic", "code": "+1-809", "short_code": "DO"},
    {"country": "East Timor", "code": "+670", "short_code": "TL"},
    {"country": "Ecuador", "code": "+593", "short_code": "EC"},
    {"country": "Egypt", "code": "+20", "short_code": "EG"},
    {"country": "El Salvador", "code": "+503", "short_code": "SV"},
    {"country": "Equatorial Guinea", "code": "+240", "short_code": "GQ"},
    {"country": "Eritrea", "code": "+291", "short_code": "ER"},
    {"country": "Estonia", "code": "+372", "short_code": "EE"},
    {"country": "Eswatini", "code": "+268", "short_code": "SZ"},
    {"country": "Ethiopia", "code": "+251", "short_code": "ET"},
    {"country": "Fiji", "code": "+679", "short_code": "FJ"},
    {"country": "Finland", "code": "+358", "short_code": "FI"},
    {"country": "France", "code": "+33", "short_code": "FR"},
    {"country": "Gabon", "code": "+241", "short_code": "GA"},
    {"country": "Gambia", "code": "+220", "short_code": "GM"},
    {"country": "Georgia", "code": "+995", "short_code": "GE"},
    {"country": "Germany", "code": "+49", "short_code": "DE"},
    {"country": "Ghana", "code": "+233", "short_code": "GH"},
    {"country": "Greece", "code": "+30", "short_code": "GR"},
    {"country": "Grenada", "code": "+1-473", "short_code": "GD"},
    {"country": "Guatemala", "code": "+502", "short_code": "GT"},
    {"country": "Guinea", "code": "+224", "short_code": "GN"},
    {"country": "Guinea-Bissau", "code": "+245", "short_code": "GW"},
    {"country": "Guyana", "code": "+592", "short_code": "GY"},
    {"country": "Haiti", "code": "+509", "short_code": "HT"},
    {"country": "Honduras", "code": "+504", "short_code": "HN"},
    {"country": "Hungary", "code": "+36", "short_code": "HU"},
    {"country": "Iceland", "code": "+354", "short_code": "IS"},
    {"country": "India", "code": "+91", "short_code": "IN"},
    {"country": "Indonesia", "code": "+62", "short_code": "ID"},
    {"country": "Iran", "code": "+98", "short_code": "IR"},
    {"country": "Iraq", "code": "+964", "short_code": "IQ"},
    {"country": "Ireland", "code": "+353", "short_code": "IE"},
    {"country": "Israel", "code": "+972", "short_code": "IL"},
    {"country": "Italy", "code": "+39", "short_code": "IT"},
    {"country": "Jamaica", "code": "+1-876", "short_code": "JM"},
    {"country": "Japan", "code": "+81", "short_code": "JP"},
    {"country": "Jordan", "code": "+962", "short_code": "JO"},
    {"country": "Kazakhstan", "code": "+7", "short_code": "KZ"},
    {"country": "Kenya", "code": "+254", "short_code": "KE"},
    {"country": "Kiribati", "code": "+686", "short_code": "KI"},
    {"country": "Kosovo", "code": "+383", "short_code": "XK"},
    {"country": "Kuwait", "code": "+965", "short_code": "KW"},
    {"country": "Kyrgyzstan", "code": "+996", "short_code": "KG"},
    {"country": "Laos", "code": "+856", "short_code": "LA"},
    {"country": "Latvia", "code": "+371", "short_code": "LV"},
    {"country": "Lebanon", "code": "+961", "short_code":""},
    {"country": "Lesotho", "code": "+266", "short_code": "LS"},
    {"country": "Liberia", "code": "+231", "short_code": "LR"},
    {"country": "Libya", "code": "+218", "short_code": "LY"},
    {"country": "Liechtenstein", "code": "+423", "short_code": "LI"},
    {"country": "Lithuania", "code": "+370", "short_code": "LT"},
    {"country": "Luxembourg", "code": "+352", "short_code": "LU"},
    {"country": "Madagascar", "code": "+261", "short_code": "MG"},
    {"country": "Malawi", "code": "+265", "short_code": "MW"},
    {"country": "Malaysia", "code": "+60", "short_code": "MY"},
    {"country": "Maldives", "code": "+960", "short_code": "MV"},
    {"country": "Mali", "code": "+223", "short_code": "ML"},
    {"country": "Malta", "code": "+356", "short_code": "MT"},
    {"country": "Marshall Islands", "code": "+692", "short_code": "MH"},
    {"country": "Mauritania", "code": "+222", "short_code": "MR"},
    {"country": "Mauritius", "code": "+230", "short_code": "MU"},
    {"country": "Mexico", "code": "+52", "short_code": "MX"},
    {"country": "Micronesia", "code": "+691", "short_code": "FM"},
    {"country": "Moldova", "code": "+373", "short_code": "MD"},
    {"country": "Monaco", "code": "+377", "short_code": "MC"},
    {"country": "Mongolia", "code": "+976", "short_code": "MN"},
    {"country": "Montenegro", "code": "+382", "short_code": "ME"},
    {"country": "Morocco", "code": "+212", "short_code": "MA"},
    {"country": "Mozambique", "code": "+258", "short_code": "MZ"},
    {"country": "Myanmar", "code": "+95", "short_code": "MM"},
    {"country": "Namibia", "code": "+264", "short_code": "NA"},
    {"country": "Nauru", "code": "+674", "short_code": "NR"},
    {"country": "Nepal", "code": "+977", "short_code": "NP"},
    {"country": "Netherlands", "code": "+31", "short_code": "NL"},
    {"country": "New Zealand", "code": "+64", "short_code": "NZ"},
    {"country": "Nicaragua", "code": "+505", "short_code": "NI"},
    {"country": "Niger", "code": "+227", "short_code": "NE"},
    {"country": "Nigeria", "code": "+234", "short_code": "NG"},
    {"country": "North Korea", "code": "+850", "short_code": "KP"},
    {"country": "North Macedonia", "code": "+389", "short_code": "MK"},
    {"country": "Norway", "code": "+47", "short_code": "NO"},
    {"country": "Oman", "code": "+968", "short_code": "OM"},
    {"country": "Pakistan", "code": "+92", "short_code": "PK"},
    {"country": "Palau", "code": "+680", "short_code": "PW"},
    {"country": "Panama", "code": "+507", "short_code": "PA"},
    {"country": "Papua New Guinea", "code": "+675", "short_code": "PG"},
    {"country": "Paraguay", "code": "+595", "short_code": "PY"},
    {"country": "Peru", "code": "+51", "short_code": "PE"},
    {"country": "Philippines", "code": "+63", "short_code": "PH"},
    {"country": "Poland", "code": "+48", "short_code": "PL"},
    {"country": "Portugal", "code": "+351", "short_code": "PT"},
    {"country": "Qatar", "code": "+974", "short_code": "QA"},
    {"country": "Romania", "code": "+40", "short_code": "RO"},
    {"country": "Russia", "code": "+7", "short_code": "RU"},
    {"country": "Rwanda", "code": "+250", "short_code": "RW"},
    {"country": "Saint Kitts and Nevis", "code": "+1-869", "short_code": "KN"},
    {"country": "Saint Lucia", "code": "+1-758", "short_code": "LC"},
    {"country": "Saint Vincent and the Grenadines", "code": "+1-784", "short_code": "VC"},
    {"country": "Samoa", "code": "+685", "short_code": "WS"},
    {"country": "San Marino", "code": "+378", "short_code": "SM"},
    {"country": "Sao Tome and Principe", "code": "+239", "short_code": "ST"},
    {"country": "Saudi Arabia", "code": "+966", "short_code": "SA"},
    {"country": "Senegal", "code": "+221", "short_code": "SN"},
    {"country": "Serbia", "code": "+381", "short_code": "RS"},
    {"country": "Seychelles", "code": "+248", "short_code": "SC"},
    {"country": "Sierra Leone", "code": "+232", "short_code": "SL"},
    {"country": "Singapore", "code": "+65", "short_code": "SG"},
    {"country": "Slovakia", "code": "+421", "short_code": "SK"},
    {"country": "Slovenia", "code": "+386", "short_code": "SI"},
    {"country": "Solomon Islands", "code": "+677", "short_code": "SB"},
    {"country": "Somalia", "code": "+252", "short_code": "SO"},
    {"country": "South Africa", "code": "+27", "short_code": "ZA"},
    {"country": "South Korea", "code": "+82", "short_code": "KR"},
    {"country": "South Sudan", "code": "+211", "short_code": "SS"},
    {"country": "Spain", "code": "+34", "short_code": "ES"},
    {"country": "Sri Lanka", "code": "+94", "short_code": "LK"},
    {"country": "Sudan", "code": "+249", "short_code": "SD"},
    {"country": "Suriname", "code": "+597", "short_code": "SR"},
    {"country": "Sweden", "code": "+46", "short_code": "SE"},
    {"country": "Switzerland", "code": "+41", "short_code": "CH"},
    {"country": "Syria", "code": "+963", "short_code": "SY"},
    {"country": "Taiwan", "code": "+886", "short_code": "TW"},
    {"country": "Tajikistan", "code": "+992", "short_code": "TJ"},
    {"country": "Tanzania", "code": "+255", "short_code": "TZ"},
    {"country": "Thailand", "code": "+66", "short_code": "TH"},
    {"country": "Togo", "code": "+228", "short_code": "TG"},
    {"country": "Tonga", "code": "+676", "short_code": "TO"},
    {"country": "Trinidad and Tobago", "code": "+1-868", "short_code": "TT"},
    {"country": "Tunisia", "code": "+216", "short_code": "TN"},
    {"country": "Turkey", "code": "+90", "short_code": "TR"},
    {"country": "Turkmenistan", "code": "+993", "short_code": "TM"},
    {"country": "Tuvalu", "code": "+688", "short_code": "TV"},
    {"country": "Uganda", "code": "+256", "short_code": "UG"},
    {"country": "Ukraine", "code": "+380", "short_code": "UA"},
    {"country": "United Arab Emirates", "code": "+971", "short_code": "AE"},
    {"country": "United Kingdom", "code": "+44", "short_code": "GB"},
    {"country": "United States", "code": "+1", "short_code": "US"},
    {"country": "Uruguay", "code": "+598", "short_code": "UY"},
    {"country": "Uzbekistan", "code": "+998", "short_code": "UZ"},
    {"country": "Vanuatu", "code": "+678", "short_code": "VU"},
    {"country": "Vatican City", "code": "+39-06", "short_code": "VA"},
    {"country": "Venezuela", "code": "+58", "short_code": "VE"},
    {"country": "Vietnam", "code": "+84", "short_code": "VN"},
    {"country": "Yemen", "code": "+967", "short_code": "YE"},
    {"country": "Zambia", "code": "+260", "short_code": "ZM"},
    {"country": "Zimbabwe", "code": "+263", "short_code": "ZW"}



]