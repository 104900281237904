import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules"; // Correct import path for modules
import "swiper/css"; // Import Swiper styles
import "swiper/css/navigation"; // Import Navigation styles
import "swiper/css/pagination"; // Import Pagination styles

import React, { useEffect, useState } from "react";
import CourseCardDwt from "./CourseCardDwt";
import { coursesData } from "./Data/courses.js";
import './Academy.css';
import { Link } from 'react-router-dom';

export default function CoursesDwt() {
  const [showSlider, setShowSlider] = useState(false);
  
  useEffect(() => {
    setShowSlider(true);
  }, []);
  
  return (
    <section className="layout-pt-lg layout-pb-lg bg-light-3">
      <div className="container">
        <div className="row justify-center text-center">
          <div className="col-auto">
            <div className="sectionTitle">
              <h2 className="sectionTitle__title">Our Popular Tech Courses</h2>
              <p className="sectionTitle__text">Shaping careers and defining the future</p>
            </div>
          </div>
        </div>

        <div className="relative pt-60 lg:pt-50 js-section-slider" data-aos="fade-left" data-aos-offset="80" data-aos-duration={800}>
          {showSlider && (
            <Swiper
              className="overflow-visible"
              modules={[Navigation, Pagination]}  
              pagination={{ el: ".event-pagination", clickable: true }}
              navigation={{ nextEl: ".course-slider-four-next", prevEl: ".course-slider-four-prev" }}
              spaceBetween={30}
              slidesPerView={1}
              breakpoints={{
                450: { slidesPerView: 1, spaceBetween: 10 },
                768: { slidesPerView: 2 },
                1200: { slidesPerView: 3 },
              }}
            >
              {coursesData.slice(0, 3).map((elm) => (
                <SwiperSlide key={elm.id}>
                  <CourseCardDwt data={elm} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}

          <button className="course-slider-four-prev section-slider-nav -prev -dark-bg-dark-2 -white -absolute size-70 rounded-full shadow-5 js-prev">
            <i className="icon icon-arrow-left text-24"></i>
          </button>

          <button className="course-slider-four-next section-slider-nav -next -dark-bg-dark-2 -white -absolute size-70 rounded-full shadow-5 js-next">
            <i className="icon icon-arrow-right text-24"></i>
          </button>
        </div>

        <div className="row justify-center pt-60 lg:pt-50">
          <div className="col-auto">
            <a 
              href="https://academy.datawiztech.com/courses/"
              className="button -icon -purple-1 text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              Browse All Courses
              <i className="icon-arrow-top-right text-13 ml-10"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
