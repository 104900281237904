import React from "react";
import { learningfeatures } from "./Data/learningPaths.js";
import './Academy.css';

import Learning6 from './image/Learning/1.png';
import { Link } from "react-router-dom";

export default function LearningPathsSix() {
    return (
      <section className="layout-pt-lg layout-pb-lg">
        <div className="container">
          <div className="row y-gap-30 justify-between items-center">
            <div className="col-xl-5 col-lg-6 col-md-9 lg:order-2">
              <h3 className="text-45 md:text-30 lh-12 fw-bold">
                <span className="text-purple-1">Learn</span> new skills when
                <br className="lg:d-none " /> and where you like.
              </h3>
              <p className="mt-20">
                Join Thousands of Learners Today and Get
                <br className="lg:d-none" /> Certified.
              </p>
              <div className="d-inline-block mt-30">
                <Link to="/courses-list" className=" button -md -purple-1 -rounded text-white">
                  Enrol Now
                </Link>
              </div>
            </div>
  
            <div className="col-lg-6 lg:order-1">
              <div className="composition -type-3">
                <div className="-el-1">
                  <div className="bg-dark-1 py-50 px-30 rounded-8">
                    <div className="y-gap-20 pt-25">
                      {learningfeatures.map((elm, i) => (
                        <div key={i} className="d-flex items-center">
                          <div className="d-flex items-center justify-center size-25 rounded-full bg-purple-1 mr-15">
                            <i className="fa fa-check text-white"></i>
                          </div>
                          <div className="fw-500 text-white">{elm.title}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="-el-2">
                  <img
                    width={580}
                    height={670}
                    style={{ width: "100%", height: "100%" }}
                    className="w-1/1"
                    src= {Learning6}
                    alt="learnp"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  