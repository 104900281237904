import React from "react";
import './Academy.css';

// Importing logos
import logo1 from './image/clients/1.svg';
import logo2 from './image/clients/2.svg';
import logo3 from './image/clients/3.svg';
import logo4 from './image/clients/4.svg';
import logo5 from './image/clients/5.svg';
import logo6 from './image/clients/6.svg';

// Array of logo imports
const brands = [logo1, logo2, logo3, logo4, logo5, logo6];

export default function Brands() {
    return (
        <section className="layout-pt-lg layout-pb-md">
            <div className="container">
                <div className="row justify-center">
                    <div className="col text-center">
                        <p className="text-lg text-dark-1">Trusted by the world’s best</p>
                    </div>
                </div>
                <div className="row y-gap-30 justify-center items-center pt-60 md:pt-50">
                    {brands.map((logo, i) => (
                        <div key={i} className="col-lg-2 col-md-3 col-sm-6 col-6 text-center">
                            <div className="d-flex justify-center items-center px-4">
                                <img
                                    className="w-100"
                                    src={logo}
                                    alt={`client ${i + 1}`}
                                    width={140}
                                    height={90}
                                    style={{ objectFit: "contain" }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
